import React, { useEffect, useState } from "react";
import {
    useBlocker,
    useLocation,
    useNavigate,
    useNavigationType,
    useParams
} from "react-router-dom";
import {
    Alert,
    Button,
    Col,
    Form,
    OverlayTrigger,
    Row,
    Tooltip
} from "react-bootstrap";
import { BackButton } from "../../simple/BackButton";
import {
    BuildType,
    CancelBatchBuildData,
    CancelBatchBuildVariables,
    CreateBatchBuildData,
    ExampleCommandData,
    Mutator,
    MutatorAssetbundlesQueryData,
    MutatorCreateData,
    MutatorQueryData,
    MutatorUpdateData,
    MutatorUpdateInput,
    MutatorUpdateVariables,
    MutatorVariables,
    TempBuildVariables,
    TempLink,
    TempLinkVariables
} from "../../../models/types";
import {
    useApolloClient,
    useLazyQuery,
    useMutation,
    useQuery,
    useSubscription
} from "@apollo/client";
import {
    GET_EXAMPLE_COMMAND,
    GET_RESULTS,
    GET_TEMP_LINK,
    MUTATOR_ASSETBUNDLES,
    MUTATOR_QUERY
} from "../../../graphql/queries";
import {
    CANCEL_BUILD,
    CREATE_BUILD,
    MUTATOR_CREATE_CONFIG,
    MUTATOR_UPDATE_CONFIG
} from "../../../graphql/mutations";
import { MutatorOptions } from "./MutatorOptions";
import { EditableJsonView } from "../../editable/EditableJsonView";
import { useLocalState } from "../../../graphql/hooks";
import {
    checkBuildDataVersion,
    updateNotificationState
} from "../../../common/Helpers";
import {
    MUTATOR_BUILD_SUBSCRIPTION,
    MUTATOR_CONFIG_SUBSCRIPTION
} from "../../../graphql/subscriptions";
import { SimpleToast } from "../../simple/SimpleToast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { fetchHtmlFromS3 } from "../../../common/MutatorHelpers";
import { AlertModal } from "../../modals/AlertModal";
import cx from "classnames";
import styles from "./MutatorView.module.scss";

const MutatorViewImplementation = () => {
    const client = useApolloClient();
    const location = useLocation();
    const { productId } = useParams<{
        productId: string;
    }>();
    const { revisionId } = useParams<{
        revisionId: string;
    }>();
    const navigate = useNavigate();
    const navigationType = useNavigationType();
    const { developerMode } = useLocalState();
    const [mutatorWorkingCopy, updateMutatorWorkingCopy] = useState<
        Mutator | undefined
    >(undefined);
    const [lastUpdatedTimeStamp, updateLastUpdatedTimestamp] = useState(
        Date.now()
    );
    const [updating, setUpdating] = useState(false);
    const [alertInfo, setAlertInfo] = useState({ message: "", variant: "" });
    const [showMutatorView, updateShowMutatorView] = useState(true);
    const [portrait, setPortrait] = useState(true);
    const [hasUnsaved, updateHasUnsaved] = useState(false);
    const [iFrameContent, setIFrameContent] = useState<string | undefined>(
        undefined
    );
    const [isBuilding, setIsBuilding] = useState(false);
    const [buildId, setBuildId] = useState(0);
    const { data: { mutatorConfig } = {} } = useQuery<
        MutatorQueryData,
        MutatorVariables
    >(MUTATOR_QUERY, {
        variables: {
            revisionId: Number(revisionId)
        }
    });

    const { data: { mutatorAssetbundles } = {} } = useQuery<
        MutatorAssetbundlesQueryData,
        MutatorVariables
    >(MUTATOR_ASSETBUNDLES, {
        variables: {
            revisionId: Number(revisionId)
        }
    });
    const [createMutatorConfig] = useMutation<
        MutatorCreateData,
        MutatorVariables
    >(MUTATOR_CREATE_CONFIG, {
        variables: {
            revisionId: Number(revisionId)
        },
        refetchQueries: [
            {
                query: MUTATOR_QUERY,
                variables: { revisionId: Number(revisionId) }
            }
        ]
    });
    const [updateMutatorMutation] = useMutation<
        MutatorUpdateData,
        MutatorUpdateVariables
    >(MUTATOR_UPDATE_CONFIG);

    const [createBuild] = useMutation<CreateBatchBuildData, any>(CREATE_BUILD);
    const [cancelBuild] = useMutation<
        CancelBatchBuildData,
        CancelBatchBuildVariables
    >(CANCEL_BUILD);

    // @ts-ignore
    const [getTempLink, { data: { generateLink } = {} }] = useLazyQuery<
        TempLink,
        TempLinkVariables
    >(GET_TEMP_LINK, { fetchPolicy: "network-only" });

    const [getExampleCommand, { data }] = useLazyQuery<
        ExampleCommandData,
        TempBuildVariables
    >(GET_EXAMPLE_COMMAND, { fetchPolicy: "network-only" });

    useSubscription(MUTATOR_BUILD_SUBSCRIPTION, {
        variables: { revisionId: revisionId },
        onData: ({
            data: { data: { mutatorBuildNotification } = {} },
            client
        }) => {
            setIsBuilding(false);

            const currentAssetBundlesData = client.cache.readQuery<{
                mutatorAssetbundles: MutatorAssetbundlesQueryData;
            }>({
                query: MUTATOR_ASSETBUNDLES,
                variables: { revisionId: Number(revisionId) }
            });

            const currentAssetBundles =
                currentAssetBundlesData?.mutatorAssetbundles;

            if (currentAssetBundles && mutatorBuildNotification) {
                client.cache.writeQuery<{
                    mutatorAssetbundles: MutatorAssetbundlesQueryData;
                }>({
                    query: MUTATOR_ASSETBUNDLES,
                    variables: { revisionId: Number(revisionId) },
                    data: {
                        mutatorAssetbundles:
                            mutatorBuildNotification.mutatorAssetbundles
                    }
                });
            }
        }
    });

    useSubscription(MUTATOR_CONFIG_SUBSCRIPTION, {
        variables: { revisionId: revisionId },
        onData: ({
            data: { data: { mutatorConfigNotification } = {} },
            client
        }) => {
            const currentCache = client.cache.readQuery<
                MutatorQueryData,
                MutatorVariables
            >({
                query: MUTATOR_QUERY,
                variables: {
                    revisionId: Number(revisionId)
                }
            });
            const currentConfig = currentCache?.mutatorConfig;
            if (currentConfig && data) {
                const version = currentConfig.mutatorConfig.version;
                const newConfig = mutatorConfigNotification.mutatorConfig;
                checkBuildDataVersion(newConfig.version, version);
                client.cache.writeQuery<MutatorQueryData, MutatorVariables>({
                    query: MUTATOR_QUERY,
                    data: {
                        mutatorConfig: {
                            ...currentConfig,
                            mutatorConfig: newConfig
                        }
                    },
                    variables: {
                        revisionId: Number(revisionId)
                    }
                });
            }
        }
    });

    useEffect(() => {
        getExampleCommand({
            variables: {
                revisionId: Number(revisionId),
                targetNetwork: "mutator"
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (mutatorConfig && mutatorConfig.mutatorKeyPrefix) {
            getTempLink({
                variables: {
                    key: mutatorConfig.mutatorKeyPrefix + "/index.html",
                    productId: Number(productId)
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mutatorConfig?.mutatorKeyPrefix]);

    useEffect(() => {
        const updateMutatorConfig = async (url: string) => {
            const finalHtmlText = await fetchHtmlFromS3(
                url,
                mutatorWorkingCopy,
                mutatorAssetbundles
            );
            setIFrameContent(finalHtmlText);
        };
        if (generateLink?.link && mutatorWorkingCopy && mutatorAssetbundles) {
            updateMutatorConfig(generateLink.link);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mutatorWorkingCopy]);

    useEffect(() => {
        const updateMutatorConfig = async (url: string) => {
            updateMutatorWorkingCopy(mutatorConfig?.mutatorConfig);
        };
        if (
            generateLink?.link &&
            mutatorConfig?.mutatorConfig &&
            mutatorAssetbundles
        ) {
            updateMutatorConfig(generateLink.link);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generateLink?.link, mutatorConfig?.mutatorConfig, mutatorAssetbundles]);

    useEffect(() => {
        if (updating) {
            setAlertInfo({
                message: "Updating changes...",
                variant: "warning"
            });
        } else if (alertInfo.message === "Updating changes...") {
            setAlertInfo({
                message: "Configuration updated successfully",
                variant: "success"
            });
            updateHasUnsaved(false);
            const timer = setTimeout(
                () => setAlertInfo({ message: "", variant: "" }),
                3000
            );
            return () => clearTimeout(timer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updating]);

    const onChange = (name: string, value: any) => {
        if (!mutatorWorkingCopy) {
            return;
        }
        const newMutator: Mutator = {
            ...mutatorWorkingCopy,
            properties: mutatorWorkingCopy.properties.map(property => {
                if (property.name === name) {
                    return { ...property, value };
                } else {
                    return { ...property };
                }
            })
        };
        updateJson(newMutator);
    };

    const updateJson = (newMutatorData: Mutator, save = false) => {
        setUpdating(true);
        updateMutatorWorkingCopy(newMutatorData);
        updateLastUpdatedTimestamp(Date.now());
        if (save) {
            saveMutatorConfig(newMutatorData);
        }
        setUpdating(false);
    };

    const saveMutatorConfig = async (newMutatorData?: Mutator) => {
        const newData = newMutatorData ? newMutatorData : mutatorWorkingCopy;
        if (newData === undefined) {
            return;
        }
        setUpdating(true);
        const input: MutatorUpdateInput = {
            revisionId: Number(revisionId),
            mutatorConfig: newData
        };
        updateMutatorMutation({
            variables: {
                input: input
            },
            update: (cache, { data }) => {
                const currentCache = cache.readQuery<
                    MutatorQueryData,
                    MutatorVariables
                >({
                    query: MUTATOR_QUERY,
                    variables: {
                        revisionId: Number(revisionId)
                    }
                });
                const currentConfig = currentCache?.mutatorConfig;
                if (currentConfig && data) {
                    const version = currentConfig.mutatorConfig.version;
                    const newConfig = data.mutatorUpdateConfig;
                    checkBuildDataVersion(
                        newConfig.mutatorConfig.version,
                        version
                    );
                    cache.writeQuery<MutatorQueryData, MutatorVariables>({
                        query: MUTATOR_QUERY,
                        data: {
                            mutatorConfig: newConfig
                        },
                        variables: {
                            revisionId: Number(revisionId)
                        }
                    });
                }
                setUpdating(false);
                updateHasUnsaved(false);
            }
        }).catch(error => {
            console.log(
                "[DEBUG] updateMutatorMutation failed with error ",
                error
            );
            // @ts-ignore
            if (
                error.message === "Build data out of sync" ||
                error.message ===
                    "resolve_mutator_update_config failed with error: out of date version in mutator config"
            ) {
                client
                    .refetchQueries({
                        include: ["mutatorConfig"]
                    })
                    .then(result => {
                        if (result.length > 0) {
                            updateNotificationState(client, false, true, {
                                success: false,
                                header: "Data out of sync",
                                message: "Data has been updated",
                                delay: 3000
                            });
                        }
                    })
                    .catch(error => {
                        console.log(
                            "[DEBUG] refetch failed with error ",
                            error
                        );
                    })
                    .finally(() => {
                        updateLastUpdatedTimestamp(Date.now());
                        setUpdating(false);
                    });
            } else {
                setUpdating(false);
            }
        });
    };

    const build = async () => {
        if (!data?.getExampleCommand) {
            return;
        }

        const variables = {
            revisionId: revisionId,
            buildData: JSON.stringify([data?.getExampleCommand]),
            buildType: BuildType.Mutator
        };

        try {
            const response = await createBuild({
                variables: variables,
                refetchQueries: [
                    {
                        query: GET_RESULTS,
                        variables: {
                            revisionId: revisionId,
                            clientTest: !developerMode
                        }
                    }
                ]
            });
            updateNotificationState(client, false, true, {
                success: true,
                header: "Build Status",
                message: `Build started successfully! (id: ${response?.data?.createBuild.id})`,
                delay: 3000
            });
            setBuildId(Number(response?.data?.createBuild.id));
            setIsBuilding(true);
        } catch (error) {
            console.log("[DEBUG] build error ", error);
            updateNotificationState(client, false, true, {
                success: false,
                header: "Build Status",
                // @ts-ignore
                message: error.message,
                delay: 3000
            });
        }
    };

    const startCancelJob = async () => {
        try {
            await cancelBuild({
                variables: { buildId: buildId },
                refetchQueries: [
                    {
                        query: GET_RESULTS,
                        variables: {
                            revisionId: revisionId,
                            clientTest: developerMode
                        }
                    }
                ]
            });
            setIsBuilding(false);
        } catch (error) {
            console.log("[DEBUG] startCancelJob error ", error);
        }
    };

    useEffect(() => {
        updateHasUnsaved(
            JSON.stringify(mutatorConfig?.mutatorConfig) !==
                JSON.stringify(mutatorWorkingCopy)
        );
    }, [mutatorWorkingCopy]);

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            hasUnsaved && currentLocation.pathname !== nextLocation.pathname
    );

    if (!mutatorWorkingCopy) {
        if (!developerMode) {
            return (
                <>
                    <Row className="p-2 text-inverse d-flex fullSize">
                        <Col className="col-3 pt-2 ps-2 pb-2">
                            <BackButton
                                hasHistory={
                                    !(location.key && navigationType === "POP")
                                }
                                goBack={() => navigate(-1)}
                                size={"lg"}
                                title="Back to Previous page"
                            />
                        </Col>
                    </Row>
                    <div className="aditorViewSmall text-inverse d-flex justify-content-center align-items-center">
                        <div>
                            <h5 className="text-center">
                                You should not be seeing this
                            </h5>
                            <br />
                            <span>
                                The developer has enabled customator, but there
                                is no configuration yet. Check back later.
                            </span>
                        </div>
                    </div>
                </>
            );
        }
        return (
            <>
                <Row className="p-2 text-inverse d-flex fullSize">
                    <Col className="col-3 pt-2 ps-2 pb-2">
                        <BackButton
                            hasHistory={
                                !(location.key && navigationType === "POP")
                            }
                            goBack={() => navigate(-1)}
                            size={"lg"}
                            title="Back to Previous page"
                        />
                    </Col>
                </Row>
                <div className="aditorViewSmall text-inverse d-flex justify-content-center align-items-center">
                    <Button onClick={() => createMutatorConfig()}>
                        Start By Creating a New Mutator Config
                    </Button>
                </div>
            </>
        );
    }

    return (
        <>
            <Row className="p-1 text-inverse d-flex fullSize">
                <Col className="p-0 m-0">
                    {developerMode ? (
                        <div className={styles.backButton}>
                            <BackButton
                                hasHistory={
                                    !(location.key && navigationType === "POP")
                                }
                                goBack={() => navigate(-1)}
                                size={"lg"}
                                title="Back to Previous page"
                            />
                        </div>
                    ) : (
                        <div
                            onClick={() =>
                                navigate(
                                    location.pathname.replace("/customator", "")
                                )
                            }
                            className={`mouseHover ${styles.clientBackButton}`}
                        >
                            <FontAwesomeIcon
                                icon={faChevronCircleLeft}
                                size="lg"
                                className="text-primary me-2"
                            />
                            <span className="text-inverse-50 bread-text">
                                Go back to Builds
                            </span>
                        </div>
                    )}
                </Col>
                <Col>
                    {!developerMode && alertInfo.message ? (
                        <div>
                            <Alert
                                className={styles.alertModal}
                                variant={alertInfo.variant}
                            >
                                <span>{alertInfo.message}</span>
                            </Alert>
                        </div>
                    ) : null}
                </Col>
                <Col className="d-flex align-items-center  justify-content-end">
                    {developerMode ? (
                        <>
                            <Form className="d-flex align-items-center pt-1">
                                <Form.Check
                                    checked={showMutatorView}
                                    onChange={() =>
                                        updateShowMutatorView(!showMutatorView)
                                    }
                                    type="switch"
                                    id="mutator-view-toggle"
                                    label="Mutator view"
                                />
                            </Form>

                            {isBuilding ? (
                                <Button
                                    onClick={() => {
                                        startCancelJob();
                                    }}
                                    className={cx(
                                        styles.createBuild,
                                        "btn-danger ms-3"
                                    )}
                                >
                                    Cancel
                                </Button>
                            ) : (
                                <Button
                                    className={cx(
                                        styles.createBuild,
                                        "btn-success ms-3",
                                        {
                                            disabled: isBuilding
                                        }
                                    )}
                                    onClick={() => {
                                        build();
                                    }}
                                >
                                    Create Mutator Build
                                </Button>
                            )}
                        </>
                    ) : null}
                </Col>
            </Row>
            {showMutatorView ? (
                <>
                    <div className={styles.mutatorView}>
                        <span className={styles.mutatorOptions}>
                            <MutatorOptions
                                mutatorConfig={mutatorWorkingCopy}
                                assetBundles={mutatorAssetbundles || []}
                                onChange={onChange}
                                onSave={() => saveMutatorConfig()}
                                updateHasUnsavedData={hasUnsaved}
                            />
                        </span>
                        <span className="mt-2 ms-3 text-inverse-50 bread-text text-center">
                            <Form.Group className="d-flex">
                                <Form.Label
                                    className={
                                        portrait ? "fw-bold text-primary" : ""
                                    }
                                >
                                    Portrait
                                </Form.Label>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="mutator-preview-toggle">
                                            Toggle preview orientation - aspect
                                            ratio 16:9
                                        </Tooltip>
                                    }
                                >
                                    <Form.Check
                                        type="switch"
                                        id="aspect-ratio-toggle"
                                        checked={!portrait}
                                        onChange={e =>
                                            setPortrait(!e.target.checked)
                                        }
                                        className="mx-2"
                                    />
                                </OverlayTrigger>
                                <Form.Label
                                    className={
                                        !portrait ? "fw-bold text-primary" : ""
                                    }
                                >
                                    Landscape
                                </Form.Label>
                            </Form.Group>
                            <iframe
                                width={portrait ? 400 : 711}
                                height={portrait ? 711 : 400}
                                title="Customator"
                                srcDoc={iFrameContent}
                            ></iframe>
                        </span>
                    </div>
                </>
            ) : (
                <div className={styles.jsonView}>
                    <EditableJsonView
                        key={lastUpdatedTimeStamp}
                        jsonData={JSON.stringify(mutatorWorkingCopy, null, 4)}
                        updateJsonData={newData => updateJson(newData, true)}
                        updating={updating}
                        updateHasUnsavedData={updateHasUnsaved}
                    />
                    {hasUnsaved && (
                        <div className="text-inverse-50 text-center bread-text">
                            Unsaved changes!
                        </div>
                    )}
                </div>
            )}
            <SimpleToast />
            {blocker.state === "blocked" ? (
                <AlertModal
                    header={<h5>Are you sure you want to leave?</h5>}
                    component="You have unsaved changes."
                    visible={() => blocker.reset()}
                    footer={
                        <Button
                            variant="warning"
                            className="m-2"
                            onClick={() => blocker.proceed()}
                        >
                            Leave
                        </Button>
                    }
                />
            ) : null}
        </>
    );
};

export const MutatorView = React.memo(MutatorViewImplementation);
